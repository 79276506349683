import React, { useState, useMemo } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { useSiteContext } from "../../../contexts/SiteProvider";
import SmartHeader from "../../../components/site/SmartHeader";
import { REVIEW_STATUS } from "../../../api/Services/ServiceUrls";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";
import { CUSTOMER_OFFERS } from "../../../api/OfferUrls";
import StarRatingImageDsiplay from "./StarRatingImageDisplay";

const StarRating = ({ closeModal, data, setLoading }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      const updatedFormData = { ...prev };
      if (value === null || value === "") {
        delete updatedFormData[name];
      } else {
        updatedFormData[name] = value;
      }
      return updatedFormData;
    });
  };

  const FormValidations = {
    description: [SmartValid.required("Description is Required")],
  };

  const formElements = [
    {
      type: "TEXTAREA",
      width: "12",
      name: "comment",
      element: {
        validations: FormValidations.description,
        max: "255",
        placeHolder: "Write A Comment...",
      },
    },
  ];

  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }

    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      // setLoading(false);
    };

    // setLoading(true, "Submitting.... Please Wait");
    const formDataWithRating = {
      ...formData,
      rating: rating,
      sd_offers_id: data.sd_offers_id,
      sd_order_items_id: data.ID,
    };
    let url = REVIEW_STATUS.REVIEW_INSERT;

    // console.log("rating data",formDataWithRating)

    const subscription = post(url, formDataWithRating, handleError).subscribe(
      (response) => {
        // setLoading(false);
        showAlertAutoClose("Rating Added Successfully", "success");
        setFormData({});
        setFormSubmit(false);
        closeModal();
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  };
  const memoizedProductImage = useMemo(() => {
    // return <ProductImage />;
    return <StarRatingImageDsiplay data={data} />;
  }, []);
  const ProductImage = () => {
    let url = CUSTOMER_OFFERS.GET_ONE_IMAGE_ID;
    let id = data?.sd_offers_id !== undefined ? data.sd_offers_id : 0;

    return (
      <>
        {/* <div className="mt-4">
          <div className="is-size-5 has-text-weight-bold my-1">
            {data?.offer_title}
          </div>
   <div className="order-info-image is-flex is-justify-content-center">
          <div>
            <SmartImageDisplay
              srcType="URL"
              url={url}
              id={id}
            />
            </div>
          </div>
        </div> */}
      </>
    );
  };

  return (
    <>
      <div className="smart-modal-close mr-3" onClick={closeModal}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
      <div className="columns">
        <div className="column is-6">{memoizedProductImage}</div>

        <div className="column is-6 mt-6">
          <div className="is-flex is-justify-content-center is-align-content-center">
            {[1, 2, 3, 4, 5].map((num) => (
              <div className="star-rating-btn" key={num}>
                <button
                  className="is-flex"
                  onClick={() => setRating(num)}
                  onMouseOver={() => setHover(num)}
                  onMouseLeave={() => setHover(rating)}
                >
                  <span
                    className={`star ${
                      num <= ((rating && hover) || hover) ? "on" : "off"
                    }`}
                  >
                    &#9733;
                  </span>
                </button>
              </div>
            ))}
          </div>
          <div>
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={formElements}
              formSubmit={formSubmit}
              handleErrorChange={handleErrorChange}
            />
          </div>
          <div className="mt-3 has-text-centered">
            <SmartSoftButton
              label="Submit"
              classList={["smart-customer-primary-button", "px-6"]}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StarRating;
