module.exports = {
  // REACT_APP_API_URL: "http://localhost/crop_php/api",
  //  REACT_APP_API_URL: 'http://10.30.1.19:8000/api',

  // Softdigi endpoint
 // REACT_APP_API_URL: "http://crop.softdigisolutions.com/api",

  // Dev Endpoint
  // REACT_APP_API_URL: 'https://cropapi.tthconsulting.com/api',

  // Prod End
   REACT_APP_API_URL: 'https://backend.cropglobalservices.com/api',

  REACT_APP_ENV: "default",
  ENCRYPTION: true,
  ENCRYPTION_KEY:
    "54686973206973206120736563726574206B657920666F7220656E6372797074696F6E",
 // STRIPE_PKEY:
  //  "pk_test_51N6UkvAxecHTIAC0OQKDR6XX4Zxyiy4hfdIoRG3KDNvUoCcr0NnMtGqPgOt14r2Lk9myZk4eDBLaOLVP8gkYAYke00TVXXhHiQ",
  //  live key
  STRIPE_PKEY:"pk_live_51N6UkvAxecHTIAC0v6wrLYivEd4RVTPxV2zG76JEufsjHSt0cHRrIhdDs2pkxlhi9W95v9fGs4ShZ00LyGgBUdp300diygumn1"
};
